import Heading from "../heading";
import styles from "./home.module.css";

const Plan = ({ price, premium, month, features }) => {
  const gradient = premium ? "to-amber-500 from-red-500" : 'black-background'
  return <div style={{ color: "black" }} className=" plan w-full flex flex-col items-center gap-4 border border-zinc-500  transition-all duration-200 cursor-pointer hover:border-red-500/50 lg:py-16 py-10">
    <h2 className="text-zinc-500\/10 flex items-end gap-0.5">
      <span className="font-extrabold text-2xl">₹</span>
      <span className="font-extrabold text-6xl">{price}</span>
      <span className="font-medium text-lg">/month</span>
    </h2>
    <h3 className={`capitalize text-base font-semibold w-full py-2 text-center  text-zinc-100 my-3 bg-gradient-to-r ${gradient}`}>{month} Membership</h3>
    <ul className="flex flex-col items-center">
      {features?.map((feature, index) => <li key={index} className="text-zinc-500\/10 py-3 text-base capitalize relative before:absolute before:bottom-0 before:left-0 before:w-full before:h-0.5 before:bg-zinc-700 last:before:w-0">{feature}</li>)}
    </ul>
  </div>
}

const PLANS = [{
  plan: "Monthly",
  price: "2000",
  month: "1 Month",
  features: ["Group Classes", "Discuss Fitness Goals", "Fitness Orientation"]
}, {
  plan: "Monthly",
  price: '5000',
  month: "3 Months",
  features: ["Group Classes", "Discuss Fitness Goals", "Fitness Orientation"]
}, {
  plan: "Monthly",
  price: "7500",
  month: "6 Months",
  features: ["Group Classes", "Discuss Fitness Goals", "Fitness Orientation"]
}, {
  plan: "Monthly",
  price: '10,000',
  month: "12 Months",
  features: ["Group Classes", "Discuss Fitness Goals", "Fitness Orientation", "1 Time Pause Membership"],
  premium: true
}]

const Nineth = () => {
  return (
    <div className={`${styles.nine} container sections-padding`}>
      <Heading title="Our Plans" subTitle="Choose the Program" />
      <div className={styles.plans}>
        {PLANS.map((plan) => <Plan {...{ ...plan }} />)}
      </div>
    </div>
  );
};

export default Nineth;
